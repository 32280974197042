import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-toggle-card[label][control]',
  templateUrl: './toggle-card.component.html',
  styleUrls: ['./toggle-card.component.scss'],
})
export class ToggleCardComponent {

  @Input() icon?: string;
  @Input() label!: string;
  @Input() description?: string;
  @Input() color?: string;
  @Input() control!: FormControl;
  @Input() isExpandable = false;
  @Input() panelExpanded = false;

  constructor() {
  }

  toggleExpansionPanel() {
    this.panelExpanded = !this.panelExpanded;
  }
}
